import { downloadKMZ } from "utils";
import { postJson } from "utils";
import { getJSON } from "jquery";
import { IFirePerimeter, IPerimeterLocation, IScAttachFile } from "./models";
import { IVisitResult } from "./models/firePage/IVisit";

export interface IDownloadKmz {
  PerimeterId?: number;
  ThreatPerimeterId?: number;
  Miles: number;
  FireName?: string;
  ObsId?: string;
  FireId?: number;
}

export interface IVisitParam {
  fireId?: number;
  clientIds?: number[];
  visitId?: number;
  propertyId?: number;
  selectedVisitDate?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface LogChipProps {
  logType: string;
  dateCreated: Date;
  attachFiles?: IScAttachFile[],
  content?: string;
  author?: string;
  jobTitle?: string;
  size?: string;
  subject?: string;
  hazards?: string;
  summary?: string;
  message?: string;
  status?: number;
  threats?: number;
  proxProps?: number;
  containment?: number;
  homesLost?: string;
  evac?: string;
  notes?: string;
  zipCodes?: string;
  perimeterId?: number;
}

export interface IFireDataTable {
  policyId: string;
  client: string | undefined;
  clientId: number | undefined;
  address: string;
  city: string;
  state: string;
  zip: string;
  threatened: any;
  distance: any;
  geocode: string | undefined;
  enrolled: any;
  numberOfBuilding: number;
  prePlan: string | undefined;
  pid: number | undefined;
  isFull: boolean;
  rowTop: number;
  rowHeight: number;
  wdsLob: number;
  selected: boolean;
  isTop: boolean;
  mapNotEnrolledColor?: string | undefined;
  mapEnrolledColor?: string | undefined;
  status: string;
  rehabRequired: any;
  location: string;
  callCount: number;
  exposure: number;
  producer: string;
  agencyName: string;
  agencyPhone: string;
  agencyCode: string;
  policies: any[];
  seqNum: string | undefined;
  active: number;
}

export async function downloadKmz(params: IDownloadKmz) {
  const url = "/api/firepage/firepage/ConstructSmokeCheckKML";
  downloadKMZ(url, params);
}

export async function getFireDetails(model: any): Promise<any> {
  var result = await postJson("/api/firepage/firepage/getFireDetails", model).then(res => res.data);
  return result ?? {};
}

export async function getResourceOrders(fireId: number): Promise<any> {
  return postJson('/api/smokecheck/fires/getresourceorders/', fireId).then(res => res);
}

export async function getPropsInBuffer(fireId: number, clientIds: number[], buffer: number): Promise<any> {
  let response = postJson('/api/firepage/firepage/GetPropertiesWithinBuffer', {fireId: fireId, clientIds: clientIds, buffer: buffer})
  return response ?? {};
}

export async function checkMGUProps10miles(id: number): Promise<any> {
  let res = getJSON("/api/sitmap/fires/checkForMGUProperties/" + id);
  return res;
}

export async function getPerimeterByFire(id: number): Promise<IFirePerimeter> {
  var result = await getJSON("/api/firepage/firepage/getPerimeterByFire/" + id);
  return result ?? {};
}

export async function getLogDetails(id: number, clientIds: number[]) {
  var result = await postJson("/api/firepage/firepage/getLogDetails", {fireId: id, clientIds: clientIds ?? [-1]});
  return result ?? [];
}

export async function getTimelinePoints(id: number, clientIds: number[], datePublished: Date) {
  var result = await postJson("/api/firepage/firepage/getTimelinePoints", {fireId: id, clientIds: clientIds ?? [-1], publishDate: datePublished})
  return result ?? [];
}

export async function getImpactStats(id: number, clientIds: number[], datePublished: Date, monitored: boolean) {
  var result = await postJson("/api/firepage/firepage/getCumulativeImpactModelStats", {fireId: id, clientIds: clientIds ?? [-1], publishDate: datePublished, monitored: monitored})
  return result ?? [];
}

export async function getGrowthModelStats(id: number, clientIds: number[], datePublished: Date, monitored: boolean) {
  var result = await postJson("/api/firepage/firepage/getGrowthModelStats", {fireId: id, clientIds: clientIds ?? [-1], publishDate: datePublished, monitored: monitored})
  return result ?? [];
}

export async function getVisitModelStatusStats(id: number, clientIds: number[], datePublished: Date) {
  var result = await postJson("/api/firepage/firepage/getVisitStatusModelStats", {fireId: id, clientIds: clientIds ?? [-1], publishDate: datePublished})
  return result ?? [];
}

export async function getPolicyholders(params: any): Promise<IFireDataTable[]> {
  var result = await postJson("/api/firepage/firepage/getPolicyholders/", { Filter: params });
  return result?.data;
}

export async function getZipCode(params: any): Promise<any> {
  var result = await postJson(
      '/api/firepage/firepage/GetZipCodes',
      { ...params }
  );
  return result ?? {};
}

export async function getEnginesDetails(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getEnginesDetails", {});
  return result ?? {};
}

export async function getVisitsAll(param: IVisitParam): Promise<IVisitResult> {
  var result = await postJson("/api/firepage/firepage/VisitAllDataForPdf", {
    Filter: {
      fireId: param.fireId,
      clientIds: param.clientIds,
    },
    Pagination: { pageSize: param.pageSize, pageNumber: param.pageNumber },
    Sorting: [
      { Name: "Address", IsDesc: false },
      { Name: "RealVisitDate", IsDesc: true },
    ],
  });
    
  return {
      dataset: result?.data,
      totalCount: result?.meta.totalCount,
    };
}

export async function getPreviousPerimeterByFire(id: number): Promise<IPerimeterLocation[]> {
  var result = await getJSON("/api/FireOverview/FireOverview/GetPreviousPerimeterByFire/" + id);
  return result ?? {};
}