import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getFireDetails, getResourceOrders, checkMGUProps10miles, getPolicyholders, getZipCode, getEnginesDetails, getLogDetails, LogChipProps, IFireDataTable, getVisitsAll, getTimelinePoints, getImpactStats, getPreviousPerimeterByFire, getGrowthModelStats, getVisitModelStatusStats, getPropsInBuffer } from "api/firePageAPI";
import { IFireDetails } from "api/models/IFirePage";
import * as atlas from "azure-maps-control";
import { getOldPerimeter, getPerimeterByFire } from 'api/sitMapAPI';
import { IClient, IPerimeterLocation } from "api/models";
import { getGeotabEngines } from "api/commonAPI";
import { ISitMapEngine } from "api/models/ISitMapEngine";
import { handlePropertyStatus } from "utils";

export interface GrowthModelStats {
  maxSize: number;
  sumMaxProp: number;
  day: string;
}

export interface VisitModelStatusStats {
  category: string;
  amount: number;
}

interface IFirePageState {
  allFireDetails?: IFireDetails[],
  fireDetails?: IFireDetails,
  resourceOrders?: any[],
  viirs?: atlas.data.FeatureCollection,
  modis?: atlas.data.FeatureCollection,
  selectedProperty?: IFireDataTable,
  highLightPolicyholder?: IFireDataTable;
  policyholders?: IFireDataTable[],
  filteredPolicyholders?: IFireDataTable[],
  mguPids?: [],
  wdsFirePerimeter?: atlas.data.FeatureCollection,
  defaultFirePerimeter?: atlas.data.FeatureCollection,
  selectedLayerIds?: string[],
  fpRightPanel: { isOpen: boolean, content: string },
  selectedClients: any[],
  availableClients: any[],
  baseMap: string,
  engines: atlas.data.FeatureCollection,
  clientsByFire: IClient[],
  zipCodes?: atlas.data.FeatureCollection,
  propertyFilters?: any,
  firePageEngines?: ISitMapEngine[],
  logDetails: LogChipProps[],
  previousFirePerimeters?: IPerimeterLocation[];
  filteredStatus?: number;
  clientsWithin?: number;
  propertiesWithin?: number;
  visitModelStats: VisitModelStatusStats[];
  growthStats: GrowthModelStats[];
  propsInOneMileBuffer: number;

}

const initialState: IFirePageState = {
  allFireDetails: [],
  fireDetails: {},
  resourceOrders: [],
  viirs: <any>{},
  modis: <any>{},
  selectedProperty: undefined,
  highLightPolicyholder: undefined,
  policyholders: [],
  filteredPolicyholders: [],
  mguPids: [],
  wdsFirePerimeter: undefined,
  defaultFirePerimeter: undefined,
  selectedLayerIds: [],
  fpRightPanel: { isOpen: false, content: 'layersPanel'},
  selectedClients: [],
  availableClients: [],
  baseMap: 'satellite_road_labels',
  engines: <any>{},
  clientsByFire: [],
  zipCodes: undefined,
  propertyFilters: {Threat: [], Enrollment: [], Status: [], Type: [], Active: []},
  firePageEngines: [],
  logDetails: [],
  previousFirePerimeters: [],
  filteredStatus: undefined,
  clientsWithin: undefined,
  propertiesWithin: undefined,
  growthStats: [],
  visitModelStats: [],
  propsInOneMileBuffer: 0
}

export const fetchFireDetails = createAsyncThunk('firePageActions/fetchFireDetails', async (model: any) => {
  let response = await getFireDetails(model);
  return response || [];
});

export const fetchResourceOrders = createAsyncThunk('firePageActions/fetchResouceOrders', async (fireId: any) => {
  let response = await getResourceOrders(fireId);
  return response || [];
});

export const checkMGUProps = createAsyncThunk('firePageActions/checkMGUProps', async (inputs: any) => {
  let response = await checkMGUProps10miles(inputs);
  return response || {};
});

export const fetchPerimeterByFire = createAsyncThunk('firePageActions/fetchPerimeterByFire', async (fireId: number) => {
  let response = await getPerimeterByFire(fireId);
  return response || {};
});

export const fetchPropsWithinBuffer = createAsyncThunk('firePageActions/fetchPropsWithinBuffer', async (params: any) => {
  let response = await getPropsInBuffer(params.fireId, params.clientIds, params.buffer);
  return response || {};
})

export const fetchOldPerimeter = createAsyncThunk('firePageActions/fetchOldPerimeter', async (perimeterId: number) => {
  let response = await getOldPerimeter(perimeterId);
  return response || {};
});

export const fetchPolicyholders = createAsyncThunk('firePageActions/fetchPolicyholders', async (params: any) => {
  let response = await getPolicyholders(params);
  return response || [];
});

export const fetchLogDetails = createAsyncThunk('firePageActions/fetchLogDetails' ,async (params: any) => {
  let response = await getLogDetails(params.fireId, params.clientIds);
  return response || {};
})

export const fetchTimelinePoints = createAsyncThunk('firePageActions/fetchTimelinePoints' ,async (params: any) => {
  let response = await getTimelinePoints(params.fireId, params.clientIds, params.datePublished);
  return response || {};
})

export const fetchImpactStats = createAsyncThunk('firePageActions/fetchImpactStats' ,async (params: any) => {
  let response = await getImpactStats(params.fireId, params.clientIds, params.datePublished, params.monitored);
  return response || {};
})

export const fetchGrowthModelStats = createAsyncThunk('firePageActions/fetchGrowthModelStats' ,async (params: any) => {
  let response = await getGrowthModelStats(params.fireId, params.clientIds, params.datePublished, params.monitored);
  return response || {};
})

export const fetchVisitModelStatusStats = createAsyncThunk('firePageActions/fetchVisitModelStats' ,async (params: any) => {
  let response = await getVisitModelStatusStats(params.fireId, params.clientIds, params.datePublished);
  return response || {};
})

export const fetchZipCode = createAsyncThunk('firePageActions/fetchZipCode', async (params: any) => {
  let filter = {
    Filter: {
      FireId: params.fireId,
    }
  }
  let response = await getZipCode(filter);
  return response || {};
});

export const fetchEnginesDetails = createAsyncThunk('firePageActions/fetchEnginesDetails', async () => {
  let response = await getEnginesDetails();
  let geotab = await getGeotabEngines();
  return { dbEngines: response, gtEngines: geotab };
});

export const fetchPropertyDetails = createAsyncThunk('firePageActions/fetchPropertyDetails', async () => {

});

export const fetchAllVisits = createAsyncThunk("firePageActions/getVisitsNoFilter", async (param: any) => {
  let response = await getVisitsAll(param);
  return response || null;
});

export const fetchPreviousPerimeterByFire = createAsyncThunk('firePageActions/fetchPreviousPerimeterByFire', async (fireId: number) => {
  let response = await getPreviousPerimeterByFire(fireId);
  return response || {};
});

const firePageActions = createSlice({
  name: "firePageActions",
  initialState,
  reducers: {
    setViirs: (state, action) => {
      state.viirs = action.payload;
    },
    setModis: (state, action) => {
      state.modis = action.payload;
    },
    setSelectedProperty: (state, action) => {
      if(typeof action.payload === 'number') {
        state.selectedProperty = state.filteredPolicyholders?.find((p: any) => p.pid == action.payload);
      } else {
        state.selectedProperty = action.payload;
      }
    },
    setSelectedLayerIds: (state, action) => {
      state.selectedLayerIds = action.payload;
    },
    setDefaultPerimeter: (state) => {
      state.wdsFirePerimeter = state.defaultFirePerimeter;
    },
    setFPRightPanel: (state, action) => {
      state.fpRightPanel = action.payload;
    },
    setSelectedClients: (state, action) => {
      let ids = action.payload.map((c: any) => c.value);
      
      var propertiesWithin = 0, clientsWithin = 0, statuses = [];
        
      for(let notice of state.allFireDetails!) {
        if(!ids.includes(notice.clientId)) continue;
        propertiesWithin += notice.propertiesWithin!;
        clientsWithin += notice.clientsWithin!;

        if(notice.wdsStatus)
          statuses.push(notice.wdsStatus);
      }
      
      const newStatus = (statuses: number[]): number => {
        if(statuses.includes(1)) return 1;
        if(statuses.includes(3)) return 3;
        return 2;
      }
      if(statuses.length) state.filteredStatus = newStatus(statuses);
      state.clientsWithin = clientsWithin;
      state.propertiesWithin = propertiesWithin;
      state.selectedClients = action.payload;
    },
    setAvailableClients: (state, action) => {
      state.availableClients = action.payload;
    },
    setBaseMap: (state, action) => {
      state.baseMap = action.payload;
    },
    setClientsByFire: (state, action) => {
      state.clientsByFire = action.payload;
    },
    setPropertyFilters: (state, action) => {
      state.propertyFilters = action.payload;
      var threat: boolean, enroll: boolean, status: boolean, type: boolean, active: boolean, clients: boolean;
      threat = enroll = status = type = active = clients = true;
      var filtered = state.policyholders?.filter((ph: any) => {
        for(const [filter, options] of Object.entries(action.payload) as any) {
          if(!options.length) continue;
          if(filter == 'Threat') {
            threat = options.includes(ph.threatened.toLowerCase());
          }
          if(filter == 'Enrollment') {
            enroll = options.includes(ph.enrolled);
          }
          if(filter == 'Status') {
            status = options.includes(handlePropertyStatus(ph.status)!.toLowerCase());
          }
          if(filter == 'Type') {
            type = options.includes(ph.wdsLob);
          }
          if(filter == 'Active') {
            active = options.includes(ph.active);
          }
          if(filter == 'clients') {
            clients = options.includes(ph.clientId)
          }
        }
        return (threat && enroll && status && type && active && clients);
      });
      state.filteredPolicyholders = filtered;
    },
    selectPolicyHolder: (state, action) => {
      if (state.policyholders?.length) {
        state.policyholders.forEach(ph => {
          if (ph.pid === action.payload) {

              ph.isTop = true;
              state.highLightPolicyholder = { ...ph };
          }
          else {
              ph.isTop = false;
          }
        });
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchFireDetails.fulfilled, (state, action) => {
        var propertiesWithin = 0, clientsWithin = 0, statuses = [];
        
        for(let notice of action.payload) {
          propertiesWithin += notice.propertiesWithin;
          clientsWithin += notice.clientsWithin;

          if(notice.wdsStatus)
            statuses.push(notice.wdsStatus);
        }
        
        const newStatus = (statuses: number[]): number => {
          if(statuses.includes(1)) return 1;
          if(statuses.includes(3)) return 3;
          return 2;
        }

        if(statuses.length) state.filteredStatus = newStatus(statuses);
        state.clientsWithin = clientsWithin;
        state.propertiesWithin = propertiesWithin;
        state.allFireDetails = action.payload;
        state.fireDetails = action.payload[0];
      })
      .addCase(fetchResourceOrders.fulfilled, (state, action) => {
        state.resourceOrders = action.payload;
      })
      .addCase(checkMGUProps.fulfilled, (state, action) => {
        state.mguPids = action.payload;
      })
      .addCase(fetchPerimeterByFire.fulfilled, (state, action) => {
        state.wdsFirePerimeter = action.payload as atlas.data.FeatureCollection;
        state.defaultFirePerimeter = action.payload as atlas.data.FeatureCollection;
      })
      .addCase(fetchLogDetails.fulfilled, (state, action) => {
        state.logDetails = action.payload;
      })
      .addCase(fetchPolicyholders.fulfilled, (state, action) => {
        var uniqueAddresses: string[] = [];
        var newData: any[] = [];
        for(var p of action.payload) {
          if(!uniqueAddresses.includes(p.address)) {
            uniqueAddresses.push(p.address);
            p.policies = [{ policy: p.policyId, client: p.client, lob: p.wdsLob, seqNum: p.seqNum, location: p.location, active: p.active }];
            newData.push(p);
          } else {
            var property = newData.find((obj: any) => obj.address == p.address);
            property.policies = [...property.policies, { policy: p.policyId, client: p.client, lob: p.wdsLob, seqNum: p.seqNum, location: p.location, active: p.active }];
          }
        }
        state.policyholders = newData;
        state.filteredPolicyholders = newData;
      })
      .addCase(fetchZipCode.fulfilled, (state, action) => {
        state.zipCodes = action.payload as atlas.data.FeatureCollection;
      })
      .addCase(fetchEnginesDetails.fulfilled, (state, action) => {
        var { dbEngines, gtEngines } = action.payload;
        const newEngines = dbEngines.map((eeng: any) => {
            const coords = gtEngines.features?.find((e: any) => e.id == eeng.geotabDeviceId)?.geometry?.coordinates;
            return {
                ...eeng,
                geotabCoords: coords
            }
        });
        state.firePageEngines = newEngines;
    })
    .addCase(fetchOldPerimeter.fulfilled, (state, action) => {
      state.wdsFirePerimeter = action.payload as atlas.data.FeatureCollection;
    })
    .addCase(fetchPreviousPerimeterByFire.fulfilled, (state, action) => {
      state.previousFirePerimeters = action.payload;
  })
    .addCase(fetchGrowthModelStats.fulfilled, (state, action) => {
      let filter: GrowthModelStats[] = [];
      action.payload.forEach((item: any) => {
        filter.push({
          maxSize: item.maxSize,
          sumMaxProp: item.sumMaxProp,
          day: item.day,
        });
      });
      state.growthStats = filter;
    })
    .addCase(fetchVisitModelStatusStats.fulfilled, (state, action) => {
      state.visitModelStats = [
        {
            category: "Undamaged",
            amount: action.payload['undamaged'] as number
        },
        {
            category: "Damaged",
            amount: action.payload['damaged'] as number
        },
        {
            category: "Lost",
            amount: action.payload['lost'] as number
        }
    ]
    })
    .addCase(fetchPropsWithinBuffer.fulfilled, (state, action) => {
      state.propsInOneMileBuffer = action.payload;
    })
});

export const {
  setViirs,
  setModis,
  setSelectedProperty,
  setSelectedLayerIds,
  setDefaultPerimeter,
  setFPRightPanel,
  setAvailableClients,
  setSelectedClients,
  setBaseMap,
  setClientsByFire,
  setPropertyFilters,
  selectPolicyHolder
  // setZipCodes
} = firePageActions.actions;
export default firePageActions.reducer;